import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import HubspotForm from "../../../components/HubspotForm/HubspotForm";
import Layout from "../../../components/Layout";

import {
  getInTouchContainer,
  imageContainer,
  contentContainer,
  content,
} from "./index.module.scss";

const GetInTouch = ({
  data: {
    strapiGetInTouch: { heading, mainCopy, image, metaData },
  },
}) => {
  return (
    <Layout
      seo={{
        title: metaData?.pageTitle,
        description: metaData?.metadescription,
        image: image.localFile.childImageSharp.gatsbyImageData,
      }}
    >
      <div className={getInTouchContainer}>
        <GatsbyImage
          className={imageContainer}
          image={image.localFile.childImageSharp.gatsbyImageData}
          alt={image.alternativeText}
        />
        <div className={contentContainer}>
          <div className={content}>
            <HubspotForm
              formId={process.env.GATSBY_GETINTOUCH_FORM_ID}
              title={heading}
              alwaysConsent={true}
              titleDescription={mainCopy}
              selectStyles={{
                option: (provided) => ({
                  ...provided,
                  color: "black",
                }),
                placeholder: (provided) => ({
                  ...provided,
                  color: "#2d2d2d",
                  opacity: 0.4,
                }),
                singleValue: (provided) => ({
                  ...provided,
                }),
                dropdownIndicator: (provided) => ({
                  ...provided,
                  color: "#2d2d2d",
                  opacity: 1,
                  "&:hover": {
                    color: "#2d2d2d",
                    opacity: 0.4,
                  },
                }),

                control: (provided, state) => ({
                  ...provided,
                  backgroundColor: "transparent",
                  border: "1px solid #2d2d2d",
                  boxShadow: "none",
                  height: "50px",
                  borderRadius: "0",
                  minWidth: 160,
                  color: "#2d2d2d",
                  cursor: "pointer",
                  marginBottom: 10,
                }),
              }}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  {
    strapiGetInTouch {
      agreementErrorText
      checkboxText
      confirmationCopy
      consentCopy
      heading
      formFieldsErrorText
      image {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
        alternativeText
      }
      mainCopy

      metaData {
        pageTitle
        metadescription
      }
    }
  }
`;

export default GetInTouch;
